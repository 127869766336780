import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Card from "react-bootstrap/Card"
import { Helmet } from "react-helmet"
import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SeoMeta from "../components/seo"

const Campus = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {
        internal: { mediaType: { eq: "image/jpeg" } }
        relativeDirectory: { eq: "campus" }
      }) {
        edges {
          node {
            relativePath
            small: childImageSharp {
              gatsbyImageData(layout: FIXED, width: 788, height: 788, quality: 100)
            }
            large: childImageSharp {
              gatsbyImageData(layout: FIXED, width: 788, quality: 100)
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges.reduce((prev, curr, idx, arr) => {
    const { node } = curr
    const n = /([0-9]+)\.jpg$/.exec(node.relativePath)
    prev[n[1]] = {
      small: getSrc(node.small),
      large: getSrc(node.large),
    }
    return prev
  }, {})
  return (
    <Layout>
      <SeoMeta title="Campus" />
      <Helmet>
        <style>{`
          .card a {
            color: white;
          }
        `}</style>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* <h3>Campus</h3> */}
            {/* <p>Our Early Childhood Center is a safe, clean and modern.  Keeping safety our priority, we have a closed campus with a secure perimeter and visitors sign in with realtime background checks.</p> */}
            {/* <div className="row">
              <div className="pb-3 col-lg-12">
                <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-258.jpg" alt="Receptionist in front of a secure check-in terminal" />
              </div>
            </div> */}
            {/* <p>Our ECC classes have all been redesigned for optimum teaching…</p> */}
            <h3>Playground Reimagined</h3>
            <p>Play! The most important part of the day. There is a purpose for every activity on our playground. While our children engage and have fun on the equipment, they develop social & emotional skills, gross motor skills, make choices, solve problems, use their imagination, become independent, and develop conversational skills.</p>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[122].small} />
                  <a href={images[122].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Triple Waterway</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-122.jpg" alt="Three children playing with toys in a segmented water channel while a little girl pumps in water with a manual lever" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[132].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img src={images[132].small} alt="Children playing in the covered sandbox" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-132.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[109].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img src={images[109].small} alt="Children playing in the covered sandbox" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-109.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[5].small} alt="Teacher watches as kids make shots into the basketball hoop" />
                  <a href={images[5].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Basketball Court</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-5.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[21].small} alt="Kids playing soccer in a grassy field" />
                  <a href={images[21].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Mini Soccer Field</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="Preschool-Photoshoot-21.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[252].small} alt="Soccer equipment" />
                  <a href={images[252].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Mini Soccer Field</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-252.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[26].small} alt="Orange Burke Orb Rocker" />
                  <a href={images[26].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Burke Orb Rocker</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-26.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[245].small} alt="Burke Nucleus Playground Equipment" />
                  <a href={images[245].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Burke Nucleus Playground Equipment</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-245.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[235].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[235].small} alt="Burke Nucleus Playground Equipment" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-235.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[239].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[239].small} alt="Burke Nucleus Playground Equipment" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-239.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[255].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[255].small} alt="Burke Nucleus Playground Equipment" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-255.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                <a href={images[224].large} data-gallery="campus-gallery" data-toggle="lightbox">
                  <Card.Img variant="top" src={images[224].small} alt="Burke Nucleus Playground Equipment" />
                </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-224.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[53].small} alt="Outdoor Art Stations" />
                  <a href={images[53].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Outdoor Art Stations</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-53.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[41].small} alt="Tricycle Track" />
                  <a href={images[41].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Tricycle Track</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-41.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[254].small} alt="ECC Obstacle Course" />
                  <a href={images[254].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>ECC Obstacle Course</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-254.jpg" alt="" /> */}
              </div>
            </div>
            <h3>Classrooms Reimagined</h3>
            <p>Each room is named after a color and is designed and arranged according to the age and development level of the students in that class. Circuit Training for the Brain:  STEAM Reimagined!  An age appropriate S.T.E.A.M. Lab (Science, Technology, Engineering, Art, Mathematics).</p>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[137].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[137].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-137.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[157].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[157].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-157.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[138].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[138].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-138.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[158].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[158].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-158.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[141].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[141].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-141.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[172].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[172].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-172.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[178].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[178].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-178.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[188].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[188].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-188.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[199].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[199].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-199.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[183].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[183].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-183.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[206].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[206].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-206.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <a href={images[211].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.Img variant="top" src={images[211].small} alt="Classrooms reimagined" />
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-211.jpg" alt="" /> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[74].small} alt="Fine Art Center" />
                  <a href={images[74].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Fine Arts</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-74.jpg" alt="" /> */}
              </div>
              <div className="col-md-4">
                <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={images[93].small} alt="Musical Arts" />
                  <a href={images[93].large} data-gallery="campus-gallery" data-toggle="lightbox">
                    <Card.ImgOverlay>
                      <Card.Title>Musical Arts</Card.Title>
                    </Card.ImgOverlay>
                  </a>
                </Card>
                {/* <Image className="w-100 img-thumbnail" src="2019-20 Preschool Photo Shoot-93.jpg" alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Campus
